@use '@angular/material' as mat;
@import 'theme.variables';
@import 'theme.palette';

// apply angular material theme
$theme-primary: mat.define-palette($primary);
$theme-accent: mat.define-palette($accent);
$theme-warn: mat.define-palette($warn);
$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn
  ),
  density: 0
));

@include mat.all-component-themes($theme);

:root {
  --textColor: $text-color;

  --primary-50: #{$primary-50-color};
  --primary-100: #{$primary-100-color};
  --primary-200: #{$primary-200-color};
  --primary-300: #{$primary-300-color};
  --primary-400: #{$primary-400-color};
  --primary-500: #{$primary-500-color};
  --primary-600: #{$primary-600-color};
  --primary-700: #{$primary-700-color};
  --primary-800: #{$primary-800-color};
  --primary-900: #{$primary-900-color};
  --primary-A100: #{$primary-A100-color};
  --primary-A200: #{$primary-A200-color};
  --primary-A400: #{$primary-A700-color};
  --primary-A700: #{$primary-A700-color};
  --primary-contrast-50: #{$primary-50-contrast};
  --primary-contrast-100: #{$primary-100-contrast};
  --primary-contrast-200: #{$primary-200-contrast};
  --primary-contrast-300: #{$primary-300-contrast};
  --primary-contrast-400: #{$primary-400-contrast};
  --primary-contrast-500: #{$primary-500-contrast};
  --primary-contrast-600: #{$primary-600-contrast};
  --primary-contrast-700: #{$primary-700-contrast};
  --primary-contrast-800: #{$primary-800-contrast};
  --primary-contrast-900: #{$primary-900-contrast};
  --primary-contrast-A100: #{$primary-A100-contrast};
  --primary-contrast-A200: #{$primary-A200-contrast};
  --primary-contrast-A400: #{$primary-A400-contrast};
  --primary-contrast-A700: #{$primary-A700-contrast};
  --accent-50: #{$accent-50-color};
  --accent-100: #{$accent-100-color};
  --accent-200: #{$accent-200-color};
  --accent-300: #{$accent-300-color};
  --accent-400: #{$accent-400-color};
  --accent-500: #{$accent-500-color};
  --accent-600: #{$accent-600-color};
  --accent-700: #{$accent-700-color};
  --accent-800: #{$accent-800-color};
  --accent-900: #{$accent-900-color};
  --accent-A100: #{$accent-A100-color};
  --accent-A200: #{$accent-A200-color};
  --accent-A400: #{$accent-A700-color};
  --accent-A700: #{$accent-A700-color};
  --accent-contrast-50: #{$accent-50-contrast};
  --accent-contrast-100: #{$accent-100-contrast};
  --accent-contrast-200: #{$accent-200-contrast};
  --accent-contrast-300: #{$accent-300-contrast};
  --accent-contrast-400: #{$accent-400-contrast};
  --accent-contrast-500: #{$accent-500-contrast};
  --accent-contrast-600: #{$accent-600-contrast};
  --accent-contrast-700: #{$accent-700-contrast};
  --accent-contrast-800: #{$accent-800-contrast};
  --accent-contrast-900: #{$accent-900-contrast};
  --accent-contrast-A100: #{$accent-A100-contrast};
  --accent-contrast-A200: #{$accent-A200-contrast};
  --accent-contrast-A400: #{$accent-A400-contrast};
  --accent-contrast-A700: #{$accent-A700-contrast};
  --warn-50: #{$warn-50-color};
  --warn-100: #{$warn-100-color};
  --warn-200: #{$warn-200-color};
  --warn-300: #{$warn-300-color};
  --warn-400: #{$warn-400-color};
  --warn-500: #{$warn-500-color};
  --warn-600: #{$warn-600-color};
  --warn-700: #{$warn-700-color};
  --warn-800: #{$warn-800-color};
  --warn-900: #{$warn-900-color};
  --warn-A100: #{$warn-A100-color};
  --warn-A200: #{$warn-A200-color};
  --warn-A400: #{$warn-A700-color};
  --warn-A700: #{$warn-A700-color};
  --warn-contrast-50: #{$warn-50-contrast};
  --warn-contrast-100: #{$warn-100-contrast};
  --warn-contrast-200: #{$warn-200-contrast};
  --warn-contrast-300: #{$warn-300-contrast};
  --warn-contrast-400: #{$warn-400-contrast};
  --warn-contrast-500: #{$warn-500-contrast};
  --warn-contrast-600: #{$warn-600-contrast};
  --warn-contrast-700: #{$warn-700-contrast};
  --warn-contrast-800: #{$warn-800-contrast};
  --warn-contrast-900: #{$warn-900-contrast};
  --warn-contrast-A100: #{$warn-A100-contrast};
  --warn-contrast-A200: #{$warn-A200-contrast};
  --warn-contrast-A400: #{$warn-A400-contrast};
  --warn-contrast-A700: #{$warn-A700-contrast};
  --grey-50: #{$gray-50-color};
  --grey-100: #{$gray-100-color};
  --grey-200: #{$gray-200-color};
  --grey-300: #{$gray-300-color};
  --grey-400: #{$gray-400-color};
  --grey-500: #{$gray-500-color};
  --grey-600: #{$gray-600-color};
  --grey-700: #{$gray-700-color};
  --grey-800: #{$gray-800-color};
  --grey-900: #{$gray-900-color};
  --grey-A100: #{$gray-A100-color};
  --grey-A200: #{$gray-A200-color};
  --grey-A400: #{$gray-A700-color};
  --grey-A700: #{$gray-A700-color};
  --grey-contrast-50: #{$gray-50-contrast};
  --grey-contrast-100: #{$gray-100-contrast};
  --grey-contrast-200: #{$gray-200-contrast};
  --grey-contrast-300: #{$gray-300-contrast};
  --grey-contrast-400: #{$gray-400-contrast};
  --grey-contrast-500: #{$gray-500-contrast};
  --grey-contrast-600: #{$gray-600-contrast};
  --grey-contrast-700: #{$gray-700-contrast};
  --grey-contrast-800: #{$gray-800-contrast};
  --grey-contrast-900: #{$gray-900-contrast};
  --grey-contrast-A100: #{$gray-A100-contrast};
  --grey-contrast-A200: #{$gray-A200-contrast};
  --grey-contrast-A400: #{$gray-A400-contrast};
  --grey-contrast-A700: #{$gray-A700-contrast};

  --live-main-300: #{$primary-300-color};
  --live-main-300-contrast: #{$primary-300-contrast};
  --live-main-500: #{$primary-500-color};
  --live-main-500-contrast: #{$primary-500-contrast};
  --live-accent-200: #{$accent-200-color};
  --live-accent-200-contrast: #{$accent-200-contrast};
  --live-accent-500: #{$accent-500-color};
  --live-accent-500-contrast: #{$accent-500-contrast};
  --live-accent-800: #{$accent-800-color};
  --live-accent-800-contrast: #{$accent-800-contrast};

  --filter-min-width: 100px;

  --max-screen-size: 1280px;
}
