// @see https://www.npmjs.com/package/@mdi/angular-material

/*button.mat-menu-item {
  line-height: 24px !important;
}*/

/*a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}*/

.mat-icon svg {
  height: 24px;
  width: 24px;
  max-height: 100%;
  max-width: 100%;
}
