@import 'theme.variables';

.header-navigation-menu {
  border-radius: 0;
}

.header-navigation-menu.mat-mdc-menu-panel {
  border-radius: 0;
  min-height: 48px;
}

.header-navigation-menu .mat-mdc-menu-content:not(:empty),
.header-navigation-menu .mat-mdc-menu-content {
  // "duplicate" selectors are required to override default styles
  padding-top: 0;
  padding-bottom: 0;
}

.header-navigation-menu .mat-mdc-menu-item.active:after {
  background: $rag-main-color;
  content: '';

  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 4px;
}

.header-navigation-menu .mat-mdc-menu-item:hover {
  color: $primary-100-contrast-var;
  background-color: $primary-100-var;
}
