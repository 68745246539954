div[mat-menu-item] > span {
  vertical-align: middle;
}

div[mat-menu-item].hidden-menu-item {
  height: 2px;
  min-height: 2px;
  opacity: 0;
  overflow: hidden;
}
