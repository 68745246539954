// primary fallback DO NOT USE
$primary-50-color: #e4e9f2;
$primary-100-color: #bbc8df;
$primary-200-color: #8ea4ca;
$primary-300-color: #6180b4;
$primary-400-color: #3f64a4;
$primary-500-color: #1d4994;
$primary-600-color: #1a428c;
$primary-700-color: #153981;
$primary-800-color: #113177;
$primary-900-color: #0a2165;
$primary-A100-color: #97adff;
$primary-A200-color: #6484ff;
$primary-A400-color: #315cff;
$primary-A700-color: #1848ff;
$primary-50-contrast: #000000;
$primary-100-contrast: #000000;
$primary-200-contrast: #000000;
$primary-300-contrast: #ffffff;
$primary-400-contrast: #ffffff;
$primary-500-contrast: #ffffff;
$primary-600-contrast: #ffffff;
$primary-700-contrast: #ffffff;
$primary-800-contrast: #ffffff;
$primary-900-contrast: #ffffff;
$primary-A100-contrast: #000000;
$primary-A200-contrast: #000000;
$primary-A400-contrast: #ffffff;
$primary-A700-contrast: #ffffff;
// primary variables to be used for styling by hand
$primary-50-var: var(--primary-50, $primary-50-color);
$primary-100-var: var(--primary-100, $primary-100-color);
$primary-200-var: var(--primary-200, $primary-200-color);
$primary-300-var: var(--primary-300, $primary-300-color);
$primary-400-var: var(--primary-400, $primary-400-color);
$primary-500-var: var(--primary-500, $primary-500-color);
$primary-600-var: var(--primary-600, $primary-600-color);
$primary-700-var: var(--primary-700, $primary-700-color);
$primary-800-var: var(--primary-800, $primary-800-color);
$primary-900-var: var(--primary-900, $primary-900-color);
$primary-A100-var: var(--primary-A100, $primary-A100-color);
$primary-A200-var: var(--primary-A200, $primary-A200-color);
$primary-A700-var: var(--primary-A400, $primary-A400-color);
$primary-A700-var: var(--primary-A700, $primary-A700-color);
$primary-50-contrast-var: var(--primary-contrast-50, $primary-50-contrast);
$primary-100-contrast-var: var(--primary-contrast-100, $primary-100-contrast);
$primary-200-contrast-var: var(--primary-contrast-200, $primary-200-contrast);
$primary-300-contrast-var: var(--primary-contrast-300, $primary-300-contrast);
$primary-400-contrast-var: var(--primary-contrast-400, $primary-400-contrast);
$primary-500-contrast-var: var(--primary-contrast-500, $primary-500-contrast);
$primary-600-contrast-var: var(--primary-contrast-600, $primary-600-contrast);
$primary-700-contrast-var: var(--primary-contrast-700, $primary-700-contrast);
$primary-800-contrast-var: var(--primary-contrast-800, $primary-800-contrast);
$primary-900-contrast-var: var(--primary-contrast-900, $primary-900-contrast);
$primary-A100-contrast-var: var(--primary-contrast-A100, $primary-A100-contrast);
$primary-A200-contrast-var: var(--primary-contrast-A200, $primary-A200-contrast);
$primary-A400-contrast-var: var(--primary-contrast-A400, $primary-A400-contrast);
$primary-A700-contrast-var: var(--primary-contrast-A700, $primary-A700-contrast);

// accent fallback DO NOT USE
$accent-50-color: #fff8e1;
$accent-100-color: #ffecb3;
$accent-200-color: #ffe082;
$accent-300-color: #ffd54f;
$accent-400-color: #ffca28;
$accent-500-color: #ffc107;
$accent-600-color: #ffb300;
$accent-700-color: #ffa000;
$accent-800-color: #ff8f00;
$accent-900-color: #ff6f00;
$accent-A100-color: #ffe57f;
$accent-A200-color: #ffd740;
$accent-A400-color: #ffc400;
$accent-A700-color: #ffab00;
$accent-50-contrast: #000000;
$accent-100-contrast: #000000;
$accent-200-contrast: #000000;
$accent-300-contrast: #000000;
$accent-400-contrast: #000000;
$accent-500-contrast: #000000;
$accent-600-contrast: #000000;
$accent-700-contrast: #000000;
$accent-800-contrast: #000000;
$accent-900-contrast: #000000;
$accent-A100-contrast: #000000;
$accent-A200-contrast: #000000;
$accent-A400-contrast: #000000;
$accent-A700-contrast: #000000;
// accent variables to be used for styling by hand
$accent-50-var: var(--accent-50, $accent-50-color);
$accent-100-var: var(--accent-100, $accent-100-color);
$accent-200-var: var(--accent-200, $accent-200-color);
$accent-300-var: var(--accent-300, $accent-300-color);
$accent-400-var: var(--accent-400, $accent-400-color);
$accent-500-var: var(--accent-500, $accent-500-color);
$accent-600-var: var(--accent-600, $accent-600-color);
$accent-700-var: var(--accent-700, $accent-700-color);
$accent-800-var: var(--accent-800, $accent-800-color);
$accent-900-var: var(--accent-900, $accent-900-color);
$accent-A100-var: var(--accent-A100, $accent-A100-color);
$accent-A200-var: var(--accent-A200, $accent-A200-color);
$accent-A700-var: var(--accent-A400, $accent-A400-color);
$accent-A700-var: var(--accent-A700, $accent-A700-color);
$accent-50-contrast-var: var(--accent-contrast-50, $accent-50-contrast);
$accent-100-contrast-var: var(--accent-contrast-100, $accent-100-contrast);
$accent-200-contrast-var: var(--accent-contrast-200, $accent-200-contrast);
$accent-300-contrast-var: var(--accent-contrast-300, $accent-300-contrast);
$accent-400-contrast-var: var(--accent-contrast-400, $accent-400-contrast);
$accent-500-contrast-var: var(--accent-contrast-500, $accent-500-contrast);
$accent-600-contrast-var: var(--accent-contrast-600, $accent-600-contrast);
$accent-700-contrast-var: var(--accent-contrast-700, $accent-700-contrast);
$accent-800-contrast-var: var(--accent-contrast-800, $accent-800-contrast);
$accent-900-contrast-var: var(--accent-contrast-900, $accent-900-contrast);
$accent-A100-contrast-var: var(--accent-contrast-A100, $accent-A100-contrast);
$accent-A200-contrast-var: var(--accent-contrast-A200, $accent-A200-contrast);
$accent-A400-contrast-var: var(--accent-contrast-A400, $accent-A400-contrast);
$accent-A700-contrast-var: var(--accent-contrast-A700, $accent-A700-contrast);

// warn fallback DO NOT USE
$warn-50-color: #f6e0e0;
$warn-100-color: #e7b3b3;
$warn-200-color: #d88080;
$warn-300-color: #c84d4d;
$warn-400-color: #bc2626;
$warn-500-color: #b00000;
$warn-600-color: #a90000;
$warn-700-color: #a00000;
$warn-800-color: #970000;
$warn-900-color: #870000;
$warn-A100-color: #ffb3b3;
$warn-A200-color: #ff8080;
$warn-A400-color: #ff4d4d;
$warn-A700-color: #ff3434;
$warn-50-contrast: #000000;
$warn-100-contrast: #000000;
$warn-200-contrast: #000000;
$warn-300-contrast: #ffffff;
$warn-400-contrast: #ffffff;
$warn-500-contrast: #ffffff;
$warn-600-contrast: #ffffff;
$warn-700-contrast: #ffffff;
$warn-800-contrast: #ffffff;
$warn-900-contrast: #ffffff;
$warn-A100-contrast: #000000;
$warn-A200-contrast: #000000;
$warn-A400-contrast: #000000;
$warn-A700-contrast: #ffffff;
// warn variables to be used for styling by hand
$warn-50-var: var(--warn-50, $warn-50-color);
$warn-100-var: var(--warn-100, $warn-100-color);
$warn-200-var: var(--warn-200, $warn-200-color);
$warn-300-var: var(--warn-300, $warn-300-color);
$warn-400-var: var(--warn-400, $warn-400-color);
$warn-500-var: var(--warn-500, $warn-500-color);
$warn-600-var: var(--warn-600, $warn-600-color);
$warn-700-var: var(--warn-700, $warn-700-color);
$warn-800-var: var(--warn-800, $warn-800-color);
$warn-900-var: var(--warn-900, $warn-900-color);
$warn-A100-var: var(--warn-A100, $warn-A100-color);
$warn-A200-var: var(--warn-A200, $warn-A200-color);
$warn-A700-var: var(--warn-A400, $warn-A400-color);
$warn-A700-var: var(--warn-A700, $warn-A700-color);
$warn-50-contrast-var: var(--warn-contrast-50, $warn-50-contrast);
$warn-100-contrast-var: var(--warn-contrast-100, $warn-100-contrast);
$warn-200-contrast-var: var(--warn-contrast-200, $warn-200-contrast);
$warn-300-contrast-var: var(--warn-contrast-300, $warn-300-contrast);
$warn-400-contrast-var: var(--warn-contrast-400, $warn-400-contrast);
$warn-500-contrast-var: var(--warn-contrast-500, $warn-500-contrast);
$warn-600-contrast-var: var(--warn-contrast-600, $warn-600-contrast);
$warn-700-contrast-var: var(--warn-contrast-700, $warn-700-contrast);
$warn-800-contrast-var: var(--warn-contrast-800, $warn-800-contrast);
$warn-900-contrast-var: var(--warn-contrast-900, $warn-900-contrast);
$warn-A100-contrast-var: var(--warn-contrast-A100, $warn-A100-contrast);
$warn-A200-contrast-var: var(--warn-contrast-A200, $warn-A200-contrast);
$warn-A400-contrast-var: var(--warn-contrast-A400, $warn-A400-contrast);
$warn-A700-contrast-var: var(--warn-contrast-A700, $warn-A700-contrast);

// gray palette
$gray-50-color: #f3f3f3;
$gray-100-color: #e1e1e1;
$gray-200-color: #cdcdcd;
$gray-300-color: #b9b9b9;
$gray-400-color: #aaaaaa;
$gray-500-color: #9b9b9b;
$gray-600-color: #939393;
$gray-700-color: #898989;
$gray-800-color: #7f7f7f;
$gray-900-color: #6d6d6d;
$gray-A100-color: #ffffff;
$gray-A200-color: #fdeaea;
$gray-A400-color: #ffb5b5;
$gray-A700-color: #ff9b9b;
$gray-50-contrast: #000000;
$gray-100-contrast: #000000;
$gray-200-contrast: #000000;
$gray-300-contrast: #000000;
$gray-400-contrast: #000000;
$gray-500-contrast: #000000;
$gray-600-contrast: #000000;
$gray-700-contrast: #000000;
$gray-800-contrast: #ffffff;
$gray-900-contrast: #ffffff;
$gray-A100-contrast: #000000;
$gray-A200-contrast: #000000;
$gray-A400-contrast: #000000;
$gray-A700-contrast: #000000;
// grey variables to be used for styling by hand
$gray-50-var: var(--grey-50, $gray-50-color);
$gray-100-var: var(--grey-100, $gray-100-color);
$gray-200-var: var(--grey-200, $gray-200-color);
$gray-300-var: var(--grey-300, $gray-300-color);
$gray-400-var: var(--grey-400, $gray-400-color);
$gray-500-var: var(--grey-500, $gray-500-color);
$gray-600-var: var(--grey-600, $gray-600-color);
$gray-700-var: var(--grey-700, $gray-700-color);
$gray-800-var: var(--grey-800, $gray-800-color);
$gray-900-var: var(--grey-900, $gray-900-color);
$gray-A100-var: var(--grey-A100, $gray-A100-color);
$gray-A200-var: var(--grey-A200, $gray-A200-color);
$gray-A700-var: var(--grey-A400, $gray-A400-color);
$gray-A700-var: var(--grey-A700, $gray-A700-color);
$gray-50-contrast-var: var(--grey-contrast-50, $gray-50-contrast);
$gray-100-contrast-var: var(--grey-contrast-100, $gray-100-contrast);
$gray-200-contrast-var: var(--grey-contrast-200, $gray-200-contrast);
$gray-300-contrast-var: var(--grey-contrast-300, $gray-300-contrast);
$gray-400-contrast-var: var(--grey-contrast-400, $gray-400-contrast);
$gray-500-contrast-var: var(--grey-contrast-500, $gray-500-contrast);
$gray-600-contrast-var: var(--grey-contrast-600, $gray-600-contrast);
$gray-700-contrast-var: var(--grey-contrast-700, $gray-700-contrast);
$gray-800-contrast-var: var(--grey-contrast-800, $gray-800-contrast);
$gray-900-contrast-var: var(--grey-contrast-900, $gray-900-contrast);
$gray-A100-contrast-var: var(--grey-contrast-A100, $gray-A100-contrast);
$gray-A200-contrast-var: var(--grey-contrast-A200, $gray-A200-contrast);
$gray-A400-contrast-var: var(--grey-contrast-A400, $gray-A400-contrast);
$gray-A700-contrast-var: var(--grey-contrast-A700, $gray-A700-contrast);

$gray: $gray-400-color;
$red: $warn-400-var;

// APPROVED
$table-grouping-color: #fcfcfc;
$header-background: #ECEEF0;
$header-text: black;
$menu-mouseover: #F6F8FA;

$icon: #505050;
//
$rag-main-color: var(--primary-500);
$menu-active-color: $rag-main-color;
$rag-accent-color: var(--accent-500);

$rag-gray: #f2f2f2;
$rag-button-text-color: #fff;

$rag-header-color: white;
$rag-footer-color: #6A6A6A;

$border-radius: 5px;

$rag-background-color: white;

// $rag-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2),
//                  1px 1px 3px 2px rgba(0, 0, 0, 0.14),
//                  1px 1px 1px 0 rgba(0, 0, 0, 0.12)
$rag-box-shadow: none;

$text-color: #111;
// $text-color-var: var(--textColor, $text-color); // todo check if this should be adjustable
$text-color-var: $text-color;


$transparent: rgba(0, 0, 0, 0);
$yellow: #E8B028;
$green: rgb(2, 161, 2);
$gold: #FFD700;
// $purple: mediumpurple;


$separator: #c1c1c1;
$tab-color: #666666;
// todo replace usage of $light-gray with $gray-100-color
$light-gray: #e7e7e7;

$table-header-border: $primary-500-var;
$table-sticky-border: $gray-400-color;


// reflact colors - card
// $primary-50-color: #e4e9f2;
$ng-background-grey: rgb(238,240,242);
$ng-black: rgb(40,50,60);
$ng-grey-dark:rgb(60,70,80);
$ng-grey-mid: rgb(120,125,130);
$ng-grey-light: rgb(160,165,170);
$ng-blue: rgb(0,80,160);
$ng-blue-light: rgb(200,240,255);
$ng-red: rgb(240,70,90);
$ng-warn: rgb(228, 85, 85);
$ng-green: rgb(90,180,10);

