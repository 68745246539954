@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'roboto-fontface/css/roboto/roboto-fontface.css';
@import 'app/styles/global/ckeditor5';
@import 'app/styles/global/ckeditor5_styles';
@import 'app/styles/global/mdi_angular-material';
@import 'app/styles/global/sk_circle';
@import 'inc_font_style';
@import 'theme.variables';
@import 'app/styles/global/flag_icons';
@import 'inc_media_queries';
@import 'app/styles/global/theme';
@import 'app/styles/global/navigation_sub_menu';
@import 'app/styles/global/material';
@import 'app/styles/global/mat_select';

:root {
  --page-background: #ffffff;
}

html, body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: $rag-background-color;
  // min-height: 100%;
  -webkit-overflow-scrolling: touch;
}

html {
  height: 100%;
  overflow-y: auto;
}

html.is-fullscreen {
  overflow: hidden;
}

html.is-fullscreen mat-sidenav-container {
  max-width: 100vw;
  min-height: 100vh;
}

a[href][disabled],
a[href][aria-disabled="true"] {
  pointer-events: none;
  cursor: default;
}

table .mat-mdc-header-cell {
  font-size: 0.875rem;
}

.invisible {
  display: none;
}

.hidden {
  display: none !important;
}

.dialog-header h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Style for widgets menu in home component
.widgets-panel-class {
  max-width: 480px !important;

  button.mat-mdc-menu-item {
    height: 80px !important;

    div.widget-description {
      color: #000;
      word-wrap: break-word;
      white-space: normal;
    }
  }

  button.mat-mdc-menu-item:disabled div.widget-description {
    display: none;
  }
}

button.mat-mdc-raised-button {
  text-overflow: ellipsis;
  overflow: hidden;
}

span.caption {
  font-size: 14px;
  padding-right: 0.1rem;
}

span.space-around {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.right8 {
  margin-right: 8px;
}

table.mat-mdc-table {

  width: 100%;

  a {
    color: black;
    text-decoration: none;
    font-weight: 400;
  }

  // headers
  tr.mat-mdc-header-row {
    th.mat-mdc-header-cell {
      color: $text-color-var;
      border-bottom-color: $table-header-border;
      // border-bottom: none;
    }

    th.settings {
      width: 40px;
    }
  }

  // rows
  tr:not(.mat-mdc-header-row) {

    .due-by-row {
      width: 150px;
    }

    .type-row {
      width: 55px;

      @media #{$mediaLT_MD} {
        display: none;
      }
    }

    .status-row {
      width: 55px;
    }

    .actions-row {
      width: 40px;

      .action-buttons {
        text-align: center;
      }
    }

    .duration-row {
      width: 100px;
    }

    .progress-bar-row {
      width: 80px;
      padding-right: 20px;
    }

    .lastValidSince-row, .startdate-row, .lastValidUntil-row {

      .date {
        color: $icon;
      }

      .time-span {
        margin-top: 0.25em;
        font-size: x-small;
      }
    }
  }

  mat-cell {
    font-size: 1em;
    color: $icon;
  }

  td.mat-mdc-cell, th.mat-mdc-header-cell {
    padding: 0 8px 0 0;
  }

  // td.mat-mdc-cell:first-of-type, th.mat-mdc-header-cell:first-of-type {
  //   padding: 0 8px 0 8px;
  // }

  // td.mat-mdc-cell:last-of-type, th.mat-mdc-header-cell:last-of-type {
  //   padding: 0 8px 0 8px;
  // }
}

table.mat-mdc-table:not(.no-hover) {
  tr:not(.mat-mdc-header-row) {
    &:hover {
      background-color: $rag-gray;
    }
  }
}

// change color of radio buttons
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $accent-500-var;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $accent-500-var;
}

.mat-mdc-dialog-actions {
  margin-left: -24px;
  padding-left: 24px !important;
  margin-right: -24px;
  padding-right: 24px !important;
  background-color: #fafafa;
  border-top: 1px solid #f0f0f0;
}

// more contrast for tooltips
.mat-mdc-tooltip-panel .mat-tooltip {
  background: #333;
  font-size: $rag-font-size-base * .94;
  white-space: pre-line;
}



/* Maintenance SnackBar */
.maintenance-danger {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

.maintenance-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border: 1px solid #bee5eb;
}

.maintenance-warning {
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
}

/* Forces a dialog to allow line breaks like a <pre> element */
.allowLineBreaks {
  white-space: pre
}

// Style for widgets menu in Report list component
.report-list-class {
  min-width: 300px !important;
}

.widthToHeightConverter {
  width: 100%;
  padding-top: 100%;
}

.checkbox-path {
  opacity: 1;
}

.sized-menu {
  max-height: 400px !important; // we need to override default material styles
  overflow: hidden !important; // ...
  width: 280px !important; // ...
  max-width: 280px !important; // ...

  .mat-mdc-menu-content {
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
  }

  .mat-mdc-menu-content > div {
    height: 100%;
    overflow: hidden;
  }
}

.no-data {
  margin-top: 6rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  color: $gray-500-color;
  font-size: small;
  width: 100%;
}
.green {
  color: green;
}
.red {
  color: red;
}

// displayOne directive styling
mat-error span[displayone]:not(:first-child),
mat-error div[displayone]:not(:first-child) {
  display: none;
}

div.tab-container {
  display: flex;
  align-items: center;
}

// mat-form-field {
//   padding-bottom: 4px;
// }

// red asterics for all required form fields
span.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  color: var(--warn-500);
  padding-left: 6px;
}

// background color for selected tab
a.mat-mdc-tab-link.mdc-tab--active,
div.mat-tab-label.mdc-tab--active {
  background-color: #f0f0f0;
  color: black;
}

// icons size for tabs
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
div.mat-tab-label-content mat-icon {
  transform: scale(0.75);
}

// selected table row
table tr.selected {
  background-color: var(--accent-50);
}

// icons color for disabled links
button[disabled="true"] mat-icon,
a[disabled="true"] mat-icon {
  fill: $gray-200-color;
}

// descriptive text to any visible block, table, section
.label {
  color: $gray-500-color;
  font-size: small;
}

.liveAgenda .dialog-header {
  background-color: var(--live-main-500) !important;
  color: var(--live-main-500-contrast) !important;
}

.clearfix:after {
  content: '';
  clear: both;
  display: table;
}

.mat-mdc-dialog-content {
  padding-top: 1em !important;
}

.strong {
  font-weight: bold;
  display: block;
  color: $primary-400-color;
  padding: 8px 0;
  margin-bottom: 20px;
}

.icon-button {
  display: flex;
  align-items: center;
}

.icon-button .mdc-button__label {
  display: inherit;
  align-items: center;
}

.mdc-button,
.mdc-tab {
  letter-spacing: normal;
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  letter-spacing: normal;
}

.mat-mdc-unelevated-button.mat-warn,
.mat-mdc-unelevated-button.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #{$primary-500-contrast};
  --mdc-filled-button-label-text-color: #{$primary-500-contrast};
}

.mat-mdc-checkbox.mat-primary,
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #{$primary-500-contrast};
  --mdc-checkbox-selected-icon-color: #{$primary-500-var};
}

.mat-mdc-dialog-actions {
  justify-content: flex-end !important;
}

.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-fab-color: #{$primary-500-contrast};
}

.spacer {
  flex-grow: 1;
}

// all icons get contrast color when in primary mat-fabs
a.mdc-fab.mat-primary {
  mat-icon {
    color: $primary-500-contrast-var;
  }
}

a.mdc-fab.mat-accent {
  mat-icon {
    color: $accent-500-contrast-var;
  }
}

.top-8 {
  margin-top: 8px;
}

.left-8 {
  margin-left: 8px;
}

.flex-horizontal {
  display: flex;
  align-items: center;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

mat-form-field.without-subscript-wrapper div.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}
