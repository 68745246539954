@import 'inc_media_queries';

.ck.ck-toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.ck.ck-editor {
  max-height: inherit;
  height: inherit !important;
}

.ck.ck-editor__main {
  max-height: inherit;
  height: calc(100% - 80px);
}

ckeditor div.ck.ck-editor__editable_inline {
  max-height: inherit;
  height: 100%;
  overflow-y: scroll !important;
}

ckeditor div.ck-source-editing-area {
  max-height: inherit;
  height: 100%;
}

ckeditor div.ck-source-editing-area textarea {
  overflow-y: auto;
}

ckeditor {
  max-height: inherit;
  height: inherit;
}

.train-macro-module-button + .ck.ck-dropdown__panel {
  width: 400px;
  overflow-y: auto;

  max-height: min(calc(100vh - 400px), 400px);
  @media (max-height: 900px) {
    max-height: min(calc(100vh - 400px), 350px);
  }
}
