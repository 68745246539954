@import 'theme.variables';

.cdk-overlay-pane .mat-mdc-dialog-container {
  position: relative;
  padding: 50px 24px 24px;
}

.mat-mdc-dialog-actions {
  justify-content: flex-end;

  @media (max-width: 500px) {
    margin-top: -8px;
    & button {
      margin-top: 8px;
    }
  }
}

.mail-composer-container .mat-mdc-dialog-container {
  padding: 0;
  width: 640px;
  height: 800px;
  max-height: 90vh;
  overflow: hidden;
}

rag-mail-composer {
  display: block;
  height: 100%;
  overflow-x: hidden;
}

// Limit the dialog width to the working areay borders
.max-width-dialog-container {
  min-width: 25vw;
  max-width: 95vw;
  max-height: 95vh;
}

.max-width-dialog-container .mat-mdc-dialog-container {
  overflow: visible;
  max-height: none;
}

.max-width-dialog-container .mat-mdc-dialog-content {
  box-sizing: border-box;
}

.single-employee-dialog {
  position: relative;
  margin-top: 200px;
  margin-bottom: 5rem;
  height: 80%;
  width: 90%;
}
