@import '~@ng-select/ng-select/themes/material.theme.css';

@import 'theme.variables';
@import 'inc_font_style';

table.mat-mdc-table {

  .mat-mdc-select-panel .mat-mdc-option {
    color: $text-color-var;
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
    &:hover:not(.mat-option-disabled),
    &:focus:not(.mat-option-disabled) {
      background: $primary-100-var;
      color: $primary-100-contrast-var;
    }

    &.mat-active {
      background: $primary-300-var;
      color: $primary-300-contrast-var;
    }
  }

  .mat-mdc-select-panel .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
    background: $primary-300-var;
    color: $primary-300-contrast-var;
  }

}

.mat-mdc-select-panel {

  .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
    background: $primary-300-var;
    color: $primary-300-contrast-var;
  }

  .mat-mdc-option:not([aria-disabled=true]) {
    color: $text-color-var;
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
    &:hover:not(.mat-option-disabled),
    &:focus:not(.mat-option-disabled) {
      background: $primary-100-var;
      color: $primary-100-contrast-var;
    }

    &.mat-active {
      background: $primary-300-var;
      color: $primary-300-contrast-var;
    }
  }
}

// TF-1810 prevent truncation of characters
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-value-text {
  overflow: visible;
  overflow-x: hidden;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-select-value {
  transform: translateY(-25%);
}
