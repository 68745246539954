.ck-styles .text-tiny {
  font-size: .7em;
}
.ck-styles .text-small {
  font-size: .85em;
}
.ck-styles .text-big {
  font-size: 1.4em;
}
.ck-styles .text-huge {
  font-size: 1.8em;
}
