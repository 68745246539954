@import "theme.variables";

// make flat and stroked buttons the same height
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  min-height: 37.313px;
}

.mat-mdc-outlined-button.mat-primary {
  color: $text-color-var;
  border-color: $primary-500-var;
}

.mat-mdc-outlined-button.mat-accent {
  color: $text-color-var;
  border-color: $accent-500-var;
}

.mat-mdc-outlined-button.mat-warn {
  color: $text-color-var;
  border-color: $warn-500-var;
}

.mat-mdc-outlined-button.rag-stroked-button {
  color: $text-color-var;
  border-color: $gray;
}

[mat-flat-button] mat-icon[svgicon="menu"],
[mat-stroked-button] mat-icon[svgicon="menu"] {
  margin: 0;
  height: 24px;
  width: 24px;
}

[mat-flat-button] mat-icon[svgicon="menu"] + .mdc-button__label,
[mat-stroked-button] mat-icon[svgicon="menu"] + .mdc-button__label {
  margin-left: 8px;
}

[mat-flat-button] mat-icon[svgicon="menu"] + .mdc-button__label:empty,
[mat-stroked-button] mat-icon[svgicon="menu"] + .mdc-button__label:empty,
[mat-stroked-button].mat-mdc-menu-trigger mat-icon[svgicon="menu"] + .mdc-button__label {
  display: none;
}

.mat-sort-header-button {
  align-items: flex-start;
  text-align: left;
}

.mat-button-fix {
  min-width: fit-content;


  & mat-icon {
    margin: 0 !important;
  }
}
